html {
  scroll-behavior: smooth;
}

.fade {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade.enter {
  opacity: 1;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Custom animations */
@layer utilities {
  .slide-in-start {
    opacity: 0;
    transform: translateX(100px);
    transition: all 0.5s ease-out;
  }

  .slide-in {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.5s ease-out;
  }
}

@layer components {
  .header {
    @apply text-4xl font-bold mb-4 text-gray-900; /* Default text color */
    font-family: "Montserrat", sans-serif;
    /* font-size: 5rem; */
    line-height: 1.1;
  }

  .theme-transition {
    @apply transition-colors duration-500 ease-out;
  }
}
